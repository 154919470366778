import React from 'react';
import { Titre } from './Titre';

const CoverComponent = () => {
  return (
<div className="flex h-auto">

<div className="hidden lg:block w-1/2 ">
    <div className="flex flex-col lg:m-10">
      <img src="images/log1.png" alt="Photo 1" className="object-cover rounded-xl mb-5 w-full h-20 transform transition-transform hover:scale-105" />

      <div className="flex">
        <div className="w-2/3 lg:mr-2.5">
          <img src="images/log3.png" alt="Photo 2" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
        </div>
        <div className="w-1/3 lg:ml-2.5">
          <img src="images/log2.png" alt="Photo 3" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
        </div>
      </div>
    </div>
  </div>

<div className="lg:w-1/2 p-4 border-2 rounded-xl bg-customGrey">
  <div className='text-customGreen text-3xl font-inter font-medium mt-5 mb-5 md:mt-10 md:mb-5'>
    Couverture
  </div>
  <div className="font-bold mb-3">
    <div>Un toit de qualité pour votre bâtiment</div>
  </div>
  <div className='mb-6'>
    <div>Notre équipe de professionnels en couverture est spécialisée dans la conception, l'installation et la réparation de toits de qualités. Que vous ayez besoin d'une nouvelle couverture pour une construction neuve ou de travaux de rénovation sur un toit existant, nous avons les compétences nécessaires pour réaliser un travail impeccable.</div>
  </div>
  <div className="font-bold mb-3">
    <div>Large Gamme de Matériaux et de Styles de Couverture</div>
  </div>
  <div className='mb-6'>
    <div>Chez Baumgart, nous comprenons que chaque projet de couverture est unique. C'est pourquoi nous proposons une vaste gamme de matériaux de couverture, de tuiles et de styles pour répondre à vos préférences esthétiques et à vos besoins fonctionnels.</div>
  </div>
  <div className="border-t-2 border-green-500 my-4"></div>
</div>
</div>



  );
}

export default CoverComponent;
