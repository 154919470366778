import React from "react";
import GalerieComponent from "../components/GalerieComponent";
import { Titre } from "../components/Titre";
import ImageCarousel from "../components/ImageCarousel";

import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; 

export const Galerie = () => {

  return (
    <main>
        <div className="mt-12">
        <ImageCarousel />
      </div>
      <Titre texte="Galerie photo" />

        <div className="p-5">
        <GalerieComponent></GalerieComponent>
        </div>

       
    </main>
  );
};

export default Galerie;

