import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

const ContactForm = () => {
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isFormIncomplete, setIsFormIncomplete] = useState(false);

  const sendEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isEmailSent || isFormIncomplete) {
      return;
    }

    const target = e.target as HTMLFormElement;
    const formData = new FormData(target);

    if (!formData.get('name') || !formData.get('email') || !formData.get('subject') || !formData.get('message')) {
      setIsFormIncomplete(true);
      return;
    }

    try {
      const response = await emailjs.sendForm('service_7yskgyj', 'template_7li93ou', target, 'djGT4G0Cz9jH6wCTm');
      console.log('E-mail sent:', response.text);
      setIsEmailSent(true);
    } catch (error) {
      console.error('Error sending e-mail:', error);
    }
  };

  return (
    <div className="lg:p-4 md:p-8 rounded-lg">
      <h1 className="lg:ml-10 lg:mb-0 text-customGreen text-3xl font-inter font-medium mb-4">Contactez-nous</h1>
      <form onSubmit={sendEmail}>
        <div className="grid grid-cols-1 lg:pt-10 lg:pl-10 lg:pr-10 md:grid-cols-2 gap-4 mb-0">
          <div className="md:col-span-2 grid grid-cols-2 gap-4">
            <input
              type="text"
              name="name"
              placeholder="Nom"
              className="w-full p-2 bg-gray-300 rounded-lg"
              required
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="w-full p-2 bg-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="md:col-span-2 grid grid-cols-1 gap-4">
            <input
              type="text"
              name="subject"
              placeholder="Objet"
              className="w-full p-2 bg-gray-300 rounded-lg"
              required
            />
          </div>
          <div className="md:col-span-2 grid grid-cols-1 gap-4">
            <textarea
              name="message"
              placeholder="Votre message..."
              className="w-full p-2 bg-gray-300 rounded-lg resize-none h-16 md:h-32"
              required
            />
          </div>
        </div>
        {isFormIncomplete && <p className="text-red-500">Veuillez remplir tous les champs requis.</p>}
        <div className="flex justify-end mt-4 lg:mr-10">
          <button type="submit" className={`bg-customGreen text-white rounded-lg p-2 flex items-center space-x-2 ${isEmailSent || isFormIncomplete ? 'cursor-not-allowed opacity-50' : ''}`}>
            <span>{isEmailSent ? 'E-mail envoyé !' : 'Envoyer'}</span>
            <FontAwesomeIcon icon={faPaperPlane} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
