import React, { useState } from 'react';

interface ProjetsComposProps {
  title: string;
  description: string;
  images: string[];
  reviews: { name: string; review: string }[];
  reviewspointListTitle: string;
  points: string[];
  ID: string;
}

const ProjetsCompos = ({ title, description, images, reviews, reviewspointListTitle, points, ID }: ProjetsComposProps) => {
  const [selectedPhoto, setSelectedPhoto] = useState(0);

  const handlePhotoSelect = (index: number) => {
    setSelectedPhoto(index);
  };

  return (
    <div id={ID} className="flex flex-col md:flex-row p-4 md:p-8 space-y-4 md:space-y-0 md:space-x-4 bg-gray-100 md:m-20 rounded-xl mt-5 lg:mr-20 lg:ml-20">
    {/* Div de gauche avec le carrousel */}
    <div className="w-full md:w-1/2 flex flex-col space-y-4 md:-mt-12 md:-ml-12">
      <div className="h-64 md:h-auto relative">
        <img
          src={images[selectedPhoto]}
          alt={`Photo ${selectedPhoto + 1}`}
          className="w-full h-full object-cover rounded-xl"
        />
      </div>
      <div className="flex justify-center space-x-4">
        {images.map((image, index) => (
           <img
           key={index}
           src={image}
           alt={`Photo ${index + 1}`}
           className={`w-16 h-16 object-cover cursor-pointer ${
             index === selectedPhoto ? 'border-4 border-blue-500' : 'opacity-50'
           } rounded-lg`} // Ajout de la classe rounded-lg
           onClick={() => handlePhotoSelect(index)}
         />
        ))}
      </div>
    </div>

    {/* Div de droite avec les détails */}
    <div className="w-full md:w-1/2 flex flex-col space-y-4 md:pr-10 md:pl-10">
      <div className="h-2/3 bg-gray p-4">
        <h2 className="text-customGreen text-xl font-semibold mb-2">{title}</h2>
        <p>{description}</p>
        <div className="mt-4">
          <h3 className="font-semibold">{reviewspointListTitle}</h3>
          <ul className="list-disc pl-6 space-y-2">
            {points.map((point, index) => (
              <li key={index}>{point}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="h-1/3 bg-customGreen2 p-4 rounded-tl-xl rounded-tr-xl rounded-br-xl">
        <p className="font-bold">Avis clients</p>
        <div className="mt-2 space-y-2">
          {reviews.map((review, index) => (
            <div key={index}>
              <p className="text-sm">{review.review}</p>
              <br />
              <p className="text-xs text-gray-600">- {review.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
  );
};

export default ProjetsCompos;
