import React from 'react';
import { Titre } from './Titre';

const CoverComponentG = () => {
  return (
    <div className="flex h-auto" id="zinguerie">

      {/* Div de gauche (contenant les textes) */}
      <div className="lg:w-1/2 p-4 border-2 rounded-xl bg-customGrey">
        <div className='text-customGreen text-3xl font-inter font-medium mt-5 mb-5 md:mt-10 md:mb-5'>
          Zinguerie
        </div>
        <div className="font-bold mb-3">
          <div>Réparation et Installation de Gouttières</div>
        </div>
        <div className='mb-6'>
          <div>Que ce soit pour réparer des gouttières endommagées ou pour installer de nouvelles gouttières, notre équipe s'engage à fournir un travail de qualité supérieure. Nous travaillons avec une variété de matériaux durables et esthétiques pour garantir que vos gouttières soient à la fois fonctionnelles et esthétiquement plaisantes.</div>
        </div>
        <div className="font-bold mb-3">
          <div>Habillage de Cheminées et Ornements en Zinguerie</div>
        </div>
        <div className='mb-6'>
          <div>De l'habillage minutieux de votre cheminée avec des détails en zinguerie aux ornements sur mesure pour les lucarnes et les façades, nous nous engageons à ajouter une esthétique exceptionnelle à votre bâtiment. Faites appel à notre équipe de zingueurs pour des créations uniques et durables qui rehaussent l'apparence de votre toiture.</div>
        </div>
        <div className="font-bold mb-3">
          <div>Des Solutions Durables et Esthétiques</div>
        </div>
        <div className='mb-6'>
          <div>Nous utilisons des matériaux de haute qualité pour garantir que nos solutions de zinguerie résistent aux conditions météorologiques les plus extrêmes. Que vous préfériez le zinc, le cuivre ou d'autres matériaux modernes, nous adaptons nos prestations à vos besoins spécifiques. </div>
        </div>
        <div className="border-t-2 border-green-500 my-4"></div>
      </div>

      {/* Div de droite (contenant les images) */}
      <div className="hidden lg:block w-1/2 ">
        <div className="flex flex-col lg:m-10">
          {/* Grande photo */}
          <img src="images/couverture.jpg" alt="Couverture" className="object-cover rounded-xl mb-5 w-full h-20 transform transition-transform hover:scale-105" />

          {/* Deux photos en bas */}
          <div className="flex">
            <div className="w-1/3 lg:mr-2.5">
              <img src="images/pointe.jpg" alt="Bardage" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
            <div className="w-2/3 lg:ml-2.5">
              <img src="images/zinc.jpg" alt="Zinc" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
          </div>
        </div>
      </div>

    </div>
  );
}

export default CoverComponentG;
