import React, { useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';

interface ProjetsCarouselProps {
  images: string[];
  captions: string[];
  IDs: string[];
  description: string[];
}

const ProjetsCarousel = ({
  images,
  captions,
  IDs,
  description,
}: ProjetsCarouselProps) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="relative ml-5 mr-5 md:ml-20 md:mr-20">
      <Carousel
        showStatus={false}
        showThumbs={false}
        infiniteLoop={true}
        autoPlay={true}
        interval={3000}
        className="rounded-lg overflow-hidden"
        onChange={handleSlideChange}
      >
        {images.map((imageUrl, index) => (
          <div key={index} className="relative h-[20rem] md:h-[30rem]">
            <img
              src={imageUrl}
              alt={`Image ${index + 1}`}
              className="w-full h-full object-cover rounded-lg"
            />

            <div className="absolute inset-0 flex items-center">
              <div className="absolute inset-0 flex items-top">
                <div className="w-full md:w-1/2 bg-gradient-to-r from-customGreen via-customGreen to-transparent flex flex-col justify-between p-5 md:p-10 ">
                  <div className="ml-2 w-1/2">
                    <p className="text-white font-bold text-lg">
                      {captions[index]}
                    </p>
                    <p className="text-white text-sm lg:mb-5 mt-1 text-left">
                      {description[index]}
                    </p>
                  </div>
                  <div className="flex items-center space-x-2 mb-5 ">
                    <a
                      href={`/Projets#${IDs[index]}`}
                      className="bg-white text-customGreen rounded-full px-3 md:px-4 py-1 md:py-2 text-sm hover:shadow-lg hover:scale-105 transition-all duration-300"
                    >
                      En savoir plus
                    </a>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      className="text-white text-sm"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default ProjetsCarousel;
