import React from 'react';
import { ImgComparisonSlider } from '@img-comparison-slider/react';

const ImageComparisonSlider = () => {
  return (
    <ImgComparisonSlider>
      <img slot="first" src="images/Mousse1.png" alt="Before" className=' rounded-xl' />
      <img slot="second" src="images/Mousse2.png" alt="After" className=' rounded-xl'/>
    </ImgComparisonSlider>
  );
};

export default ImageComparisonSlider;
