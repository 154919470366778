import ImageCarousel from '../components/ImageCarousel';
import { Titre } from "../components/Titre";
import { Intro } from "../components/Intro";
import { OverlappingDivs } from '../components/OverlappingDivs';
import Card from '../components/Card';
import ProjetsCarousel from '../components/ProjetsCarousel';
import EmbeddedMap from '../components/EmbeddedMap';
import QualityLogos from '../components/QualityLogos';
import ArticleComponent from '../components/ArticleComponent';
import { SousTitre } from '../components/SousTitre';
import { TitrePrincipal } from '../components/TitrePrincipal';

export const Accueil = () => {
    const qualityLogos = [
        'images/qualite1.png',
        'images/logo40.png',
        'images/CMA.jpg',
        // Ajoutez les URLs des logos de qualité
    ];

    

    return (
        <main>
            {/* Header */}
            <div className="mt-12">
                {/* Contenu du header */}
            </div>

            {/* Image d'arrière-plan avec le titre */}
            <div className="relative h-screen overflow-hidden">
                {/* Image d'arrière-plan */}
                <div className="absolute top-0 left-0 w-full h-full zoom-effect bg-fixed bg-no-repeat bg-cover bg-center overflow-hidden"
    style={{
      backgroundImage: `linear-gradient(to top, rgba(0,0,0,0.7), transparent), url('images/312259346_984527419157936_6845056137523166709_n.jpg')`,
      margin: '-0px', // Réduire la taille du fond plein écran de 10 pixels
    }}
  ></div>
                {/* Titre superposé */}
                <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-4xl font-bold">
                    <TitrePrincipal texte="BAUMGART & Fils" />
                    <SousTitre texte="Depuis 1983, nous mettons notre expertise à votre service" />
                    <div className="absolute left-1/2 transform -translate-x-1/2">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 text-white animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
                        </svg>
                      </div>
                </div>
            </div>

            <Titre texte="Découvrez notre entreprise de toiture et couverture" />

            {/* Contenu principal */}
            <div className="mt-12">
                <Intro />

                <SousTitre texte="Il parle de nous !" />
                <div>
                    <ArticleComponent />
                </div>
            </div>

            <SousTitre texte="Nos différents services d'entreprise de toiture et couverture" />
            
            <div className="mx-4 md:mx-0 mt-4" style={{
                backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, transparent), url(images/bardage.jpg)',
                backgroundSize: 'cover',
                backgroundPosition: 'center bottom',
            }}>
<div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mx-2 md:mx-10 p-4 md:p-10 rounded-xl" style={{ backgroundImage: "url('images/fondcarte.jpeg')" , backgroundSize: "cover", backgroundPosition: "center center" }}>

                    <div className="w-full md:w-1/3 flex justify-center items-center">
                        <Card
                            title="Couverture"
                            text="Des toits solides, une protection fiable. Confiez votre couverture à notre entreprise de toiture."
                            logoUrl="images/iconetoit.png"
                            sectionId="couverture"
                            backgroundColor='bg-customGrey'
                            titleColor='text-customGreen'
                        />
                    </div>
                    <div className="w-full md:w-1/3 flex justify-center items-center">
                        <Card
                            title="Zinguerie"
                            text="Étanchéité garantie. Travaux sur mesure. Découvrez nos services de zinguerie professionnels."
                            logoUrl="images/iconesouder.png"
                            sectionId="zinguerie"
                            backgroundColor='bg-customGrey'
                            titleColor='text-customGreen'
                        />
                    </div>
                    <div className="w-full md:w-1/3 flex justify-center items-center">
                        <Card
                            title="Bardage & Isolation"
                            text="Confort et esthétique réunis. Optez pour notre isolation et nos bardages de qualité."
                            logoUrl="images/iconebardage.png"
                            sectionId="isolation"
                            backgroundColor='bg-customGrey'
                            titleColor='text-customGreen'
                        />
                    </div>
                </div>
            </div>

            <SousTitre texte="Nos Projets" />
            <div className="mt-12">
                <ProjetsCarousel
                    images={[
                        'images/carousel1.png',
                        'images/carousel2.png',
                        'images/carousel3.png',
                        // ... Ajoutez autant d'images que nécessaire
                    ]}
                    captions={[
                        'Toiture à Logelbach',
                        'Maison au centre ville de Munster',
                        'Marcairie perdue dans la montagne',
                        // ... Ajoutez autant de légendes que nécessaire
                    ]}
                    description={[
                        "Couverture en tuiles Arboise rectangulaire couleur ardoisée de chez Edilians, très beau résultat final qui rappelle l'ancienne couverture en ardoise, Habillage des planches de rives en tole prélaquée blanche pour l'accord avec la façade qui a été isolée.",
                        "Maison au centre ville de Munster, le magasin de chaussures Élégance à revêtu sa nouvelle toiture en Kergoat de chez Eternit. Avec réfection de la charpente des lucarnes, habillés sur mesures en tôles prélaquée blanche Ral 9010 ",
                        "Encore une marcairie perdue dans la montagne rénovée. Les deux bâtiments remis à neuf. Couverture en tuiles H10 nuagés de chez Edilians, avec isolation en sarking épaisseur 130mm, R = 6. Le top pour une maison en montagne",
                        // ... Ajoutez autant de descriptions que nécessaire
                    ]}
                    IDs={[
                        'p1',
                        'p2',
                        'p3',
                        // ... Ajoutez autant de légendes que nécessaire
                    ]}
                />
            </div>

            <SousTitre texte="Retrouvez-nous ici" />
            <EmbeddedMap apiKey="AIzaSyB543agVBBVe77o50drfonN65waQ9TEq3E" latitude={48.8566} longitude={2.3522} />

            <SousTitre texte="Une entreprise qualifiée en toiture et couverture" />
            <QualityLogos logos={qualityLogos} />
        </main>
    );
}
