
export const photos = [
    {
        src:"../images/images_galerie/proj43.png",
        width:4,
        height:3
      },
      {
        src:"../images/images_galerie/proj4.png",
        width:16,
        height:3
      },
      {
        src:"../images/images_galerie/proj44.png",
        width:4,
        height:3
      },
      {
        src:"../images/images_galerie/proj42.png",
        width:16,
        height:3
      },

    {
      src:"../images/images_galerie/bardage_bac_acier.webp",
      width:4,
      height:3
  },
  {
      src:"../images/images_galerie/couverture_en_bac_acier.webp",
      width:4,
      height:3
  },
  {
      src:"../images/images_galerie/IMG_20230829_WA0004.webp",
      width:4,
      height:3
  },
  {
      src:"../images/images_galerie/IMG_20230829_WA0005.webp",
      width:4,
      height:3
  },
  {
      src:"../images/images_galerie/IMG_20230829_WA0007.webp",
      width:4,
      height:3
  },
  {
      src:"../images/15420764_630353423837235_1690352166496432197_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/21317873_870633526475889_2063133381372425249_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/22365357_900091103530131_6999899361580794274_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/36784562_1115935391945700_1806032906154409984_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/38279378_1149460345259871_2347393441958199296_n.jpg",
      width:16,
      height:9
  },
  {
      src:"../images/116764239_1800412506831315_7754877139507890182_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/118614772_1824660637739835_6076620881376238345_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/128493866_1915586701980561_4007947254753002294_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/131132470_1929362730602958_6783490305865008598_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/186493188_2051500301722533_3294533782353867087_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/194260199_2065441660328397_6508618786105094934_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/216420076_2096924940513402_8178405391033307794_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/223912436_2108322216040341_7194193848677004787_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/245060369_2168829633322932_7913333650967787196_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/275227213_2284979428374618_5381926355804219406_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/275557038_2284979558374605_8550911141983015086_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/301772285_930203747923637_5891857332686143223_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/306504043_940486720228673_5069551986298979742_n.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/bac.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/bardage2.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/carouselprojet1.png",
      width:16,
      height:9
  },
  {
      src:"../images/isolation.jpg",
      width:16,
      height:9
  },
  {
      src:"../images/log1.png",
      width:1,
      height:1
  },
  {
      src:"../images/log2.png",
      width:1,
      height:1
  },
  {
      src:"../images/log3.png",
      width:1,
      height:1
  },
  {
      src:"../images/log4.png",
      width:1,
      height:1
  },
  {
      src:"../images/mar1.png",
      width:1,
      height:1
  },
  {
      src:"../images/mar2.png",
      width:1,
      height:1
  },
  {
      src:"../images/mar3.png",
      width:1,
      height:1
  },
  {
      src:"../images/mar4.png",
      width:1,
      height:1
  },
  {
      src:"../images/Mousse1.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/munst1.png",
      width:1,
      height:1
  },
  {
      src:"../images/munst2.png",
      width:1,
      height:1
  },
  {
      src:"../images/munst3.png",
      width:1,
      height:1
  },
  {
      src:"../images/munst4.png",
      width:1,
      height:1
  },
  {
      src:"../images/projets2.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/projets2dim.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/projets3.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/projets3dim.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/projets4dim.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/projets5dim.jpg",
      width:4,
      height:3
  },
  {
      src:"../images/tuile.jpg",
      width:16,
      height:9
  },
  {
      src:"../images/tuiles4.jpg",
      width:16,
      height:9
  }

    // Ajoutez autant d'objets photo que nécessaire
  ];
