import ImageCarousel from '../components/ImageCarousel';
import { PrestationsD } from '../components/PrestationsD';
import { PrestationsG } from '../components/PrestationsG';
import { AvantApres } from '../components/AvantApres';
import { Titre } from '../components/Titre';
import Presta2 from '../components/Presta2';
import CoverComponent from '../components/CoverComponent';
import CoverComponentG from '../components/CoverComponentG';
import CoverComponentH from '../components/CoverComponentH';
import CoverComponentJ from '../components/CoverComponentJ';
import QualityLogos from '../components/QualityLogos'; 
import React, { useEffect } from 'react';
import { SousTitre } from '../components/SousTitre';

export const Prestations = () => {
    const qualityLogos = [
        'images/qualite1.png',
        'images/logo40.png',
        'images/CMA.jpg',
        // Ajoutez les URLs des logos de qualité
    ];

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sectionId = urlParams.get('section');
        const offsetPercentage = parseFloat(urlParams.get('offset') || '0');

        if (sectionId) {
            const targetElement = document.getElementById(sectionId);
            if (targetElement) {
                const windowHeight = window.innerHeight;
                const offsetPixels = windowHeight * (offsetPercentage / 100);
                const scrollToPosition = targetElement.offsetTop - offsetPixels;

                window.scrollTo({ top: scrollToPosition, behavior: 'smooth' });
            }
        }
    }, []);
      
    return ( 
        <main>
            <div className='mt-12'>
                <ImageCarousel />
                <Titre texte="Nos différentes préstations" />

                <div id="couverture" className='p-5 lg:m-10 rounded-lg'>
                    <CoverComponent />
                    <AvantApres title="Demoussage" content="Nos prestations de démoussage de toiture sont conçues pour maintenir votre maison en excellent état. Au fil du temps, les toits accumulent de la mousse et d'autres saletés qui peuvent endommager la structure et réduire l'efficacité de vos tuiles. Nous offrons un service de démoussage complet qui élimine en douceur ces dépôts indésirables. " imageSrc='images/Mousse1.jpg' />
                </div>
                <div id='zinguerie' className='bg-white p-5 lg:m-10 rounded-lg'>
                    <CoverComponentG />
                </div>
                <div id='isolation' className='bg-white p-5 lg:m-10 rounded-lg'>
                    <CoverComponentH />
                </div>
                <div className='bg-white p-5 lg:m-10 rounded-lg'>
                    <CoverComponentJ />
                </div>
            </div>
            <SousTitre texte="Une entreprise qualifiée en prestations de toiture et couverture" />
            <QualityLogos logos={qualityLogos} />
        </main>
    )
}

export default Prestations;
