import React from 'react';

interface EmbeddedMapProps {
    apiKey: string;
    latitude: number;
    longitude: number;
}
    

const EmbeddedMap = ({ apiKey, latitude, longitude }: EmbeddedMapProps) => {
  const mapSrc = `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=41+Grand+Rue,Metzeral`;

  return (
    <div className="w-full h-[50vh] border-t-2 border-b-2 border-green-500  mt-4 mb-4">
      <iframe
        title="Embedded Map"
        width="100%"
        height="100%"
        frameBorder="0"
        src={mapSrc}
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default EmbeddedMap;

