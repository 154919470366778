import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import React from 'react';

const ContactInfo = () => {
  return (

    <div className="lg:p-4 md:p-8 rounded-lg">
        <h1 className="lg:ml-10 lg:mb-0 text-customGreen text-3xl font-inter font-medium mb-4">Appelez-nous</h1>
        <div className="lg:ml-20 lg:mr-20 md:mt-16 flex bg-gray-300 rounded-lg p-4">
            <div className="w-2/6 flex items-center justify-center">
            <FontAwesomeIcon icon={faPhone} bounce className="text-green-500 h-10 w-10" />
            </div>
            <div className="w-4/6">
            <p className="whitespace-pre">
                Baumgart & fils Sarl
                {"\n"}03 89 21 69 39
            </p>
            </div>
        </div>
  
  
      <div className="lg:ml-10 lg:mt-4 flex items-center space-x-2">
        <h2 className="text-customGreen text-3xl font-inter font-medium mb-4 mt-10">Suivez-nous sur Facebook</h2>
        <a
          href="https://www.facebook.com/baumgartandreetfils/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-green-500 hover:underline flex items-center space-x-2"
        >
          <FontAwesomeIcon icon={faFacebook} className="ml-5 mt-7 h-10 w-10" /> {/* Augmentez la taille de l'icône */}
          
        </a>
      </div>
    </div>
  );
};

export default ContactInfo;
