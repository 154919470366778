import React, { useState, useEffect } from 'react';
import { Accueil } from './screens/Accueil';
import { Routes, Route, NavLink } from "react-router-dom";
import { Prestations } from './screens/Prestations';
import { Contact } from './screens/Contact';
import Projets from "./screens/Projets"
import Galerie from "./screens/Galerie"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import Footer from './components/Footer';
import NotFound from './components/NotFound';
import { MentionsLegales } from './components/MentionsLegales';

function App() {

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
  const [visible, setVisible] = useState(true);

  const handleLinkClickMobile = () => {
    window.scrollTo(0, 0);
    toggleMenu();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 20);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (!isMobile && menuOpen) {
        setMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [menuOpen, isMobile]);

  useEffect(() => {
    if (!isMobile) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [isMobile, prevScrollPos]);

  useEffect(() => {
    const handleLinkClick = () => {
      window.scrollTo(0, 0);
    };

    const menuLinks = document.querySelectorAll('.mobile-menu-link');
    menuLinks.forEach(link => {
      link.addEventListener('click', handleLinkClick);
    });

    return () => {
      menuLinks.forEach(link => {
        link.removeEventListener('click', handleLinkClick);
      });
    };
  }, []);

  return (
    <div>
      <header className={`fixed top-0 left-0 w-full bg-white shadow-md p-2 z-10 mt-0 mb-4 ${visible ? 'translate-y-0 transition-transform duration-300' : '-translate-y-full transition-transform duration-300 ease-in-out'}`}>
        <div className='hidden md:flex md:justify-between md:items-center'>
          <div className="flex items-center">
            <a href="/" className="flex items-center">
              <img
                className="h-10 md:h-16 lg:h-20 mr-2 ml-5"
                src="images/logotitre.png"
                alt="logo de l'entreprise"
              />
            </a>
          </div>
          <nav className='flex justify-center items-center'>
            <NavLink
              className="mr-7 mobile-menu-link "
              to="/"
              style={({ isActive }) => ({ color: isActive ? '#A6CE3E' : '#3FB44C' })}
            >
              Accueil
            </NavLink>
            <NavLink
              className="mr-7 mobile-menu-link"
              to="/Prestations"
              style={({ isActive }) => ({ color: isActive ? '#A6CE3E' : '#3FB44C' })}
            >
              Prestations
            </NavLink>
            <NavLink
              className="mr-7 mobile-menu-link"
              to="/Projets"
              style={({ isActive }) => ({ color: isActive ? '#A6CE3E' : '#3FB44C' })}
            >
              Projets
            </NavLink>
            <NavLink
              className="mr-7 mobile-menu-link"
              to="/Galerie"
              style={({ isActive }) => ({ color: isActive ? '#A6CE3E' : '#3FB44C' })}
            >
              Galerie
            </NavLink>
            <NavLink
              className="mr-7 mobile-menu-link"
              to="/Contact"
              style={({ isActive }) => ({
                backgroundColor: isActive ? '#A6CE3E' : '#3FB44C',
                color: 'white',
                display: 'inline-flex',
                alignItems: 'center',
                borderRadius: '0.5rem',
                padding: '0.5rem 1rem',
              })}
            >
              <button>Contactez-nous</button>
            </NavLink>
          </nav>
        </div>
        {isMobile && (
          <div className='md:hidden flex justify-between items-center'>
            <div className='flex items-center ml-2'>
              <img
                className='h-10 md:h-16 lg:h-20'
                src='images/logotitre.png'
                alt="logo de l'entreprise"
              />
            </div>
            <button
              id="menu-toggle"
              className={`flex items-center justify-center w-10 h-10 bg-customGreen rounded-full text-white transition-transform transform ${menuOpen ? 'rotate-90' : 'rotate-0'}`}
              onClick={toggleMenu}
              aria-label={menuOpen ? 'Fermer le menu' : 'Ouvrir le menu'}
            >
              <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
            </button>
          </div>
        )}
        {isMobile && menuOpen && (
          <nav className={`md:hidden ${menuOpen ? 'block' : 'hidden'}`}>
            <div className='mt-2 md:mt-0 md:flex md:flex-col'>
              <NavLink
                className='block md:inline-block text-gray-700 mb-2 md:mb-0 ml-4 md:ml-0 text-sm mobile-menu-link'
                to="/"
                onClick={handleLinkClickMobile}
              >
                Accueil
              </NavLink>
              <NavLink
                className='block md:inline-block text-gray-700 mb-2 md:mb-0 ml-4 md:ml-0 text-sm mobile-menu-link'
                to="/Prestations"
                onClick={handleLinkClickMobile}
              >
                Prestations
              </NavLink>
              <NavLink
                className='block md:inline-block text-gray-700 mb-2 md:mb-0 ml-4 md:ml-0 text-sm mobile-menu-link'
                to="/Projets"
                onClick={handleLinkClickMobile}
              >
                Projets
              </NavLink>

              <NavLink
              className='block md:inline-block text-gray-700 mb-2 md:mb-0 ml-4 md:ml-0 text-sm mobile-menu-link'
                to="/Galerie"
                onClick={handleLinkClickMobile}
              >
                Galerie
                
              </NavLink>

              <NavLink
                className='block md:inline-block text-gray-700 mb-2 md:mb-0 ml-4 md:ml-0 text-sm mobile-menu-link'
                to="/Contact"
                onClick={handleLinkClickMobile}
              >
                Contactez-nous
              </NavLink>
            </div>
          </nav>
        )}
      </header>

      <Routes>
        <Route path="/" element={<Accueil />} />
        <Route path="/Prestations" element={<Prestations />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path='/Projets' element={<Projets />} />
        <Route path='/Galerie' element={<Galerie />} />
        <Route path='/mentions-legales' element={<MentionsLegales />} />
        <Route path="*" element={<NotFound />} />
      </Routes>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
  
export default App;
       
