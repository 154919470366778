import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageCarousel = () => {
  return (
    <Carousel
      showStatus={false}
      infiniteLoop
      autoPlay
      interval={6000} // Interval entre les images (en millisecondes)
      className="w-full carouselHeight-fixed mt-20" // Utilisez la classe de hauteur personnalisée
      //masqué les appercus
      showThumbs={false}
      //masqué les boutons de navigation
      showIndicators={false}
      //Masqué les boutons de control
      showArrows={false}

    >
      <div>
        <img
          src="images/carouselprincipal.png"
          alt="Annonce"
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <img
          src="images/carouselsecondaire.png"
          alt="Annonce"
          className="w-full h-full object-cover"
        />
      </div>
      <div>
        <img
          src="images/carouseltrois.png"
          alt="Annonce"
          className="w-full h-full object-cover"
        />
      </div>

    </Carousel>
  );
};

export default ImageCarousel;
