import ImageCarousel from '../components/ImageCarousel';
import ProjetsCompos from '../components/ProjetsCompos';
import { Titre } from '../components/Titre';
import { Intro2 } from "../components/Intro2";
import React, { useEffect } from 'react';

const Projets = () => {
    useEffect(() => {
        const handleHashChange = () => {
            const hash = window.location.hash;
            if (hash) {
                const targetElement = document.querySelector(hash);
                if (targetElement) {
                    // Calculer la position de l'élément cible
                    const targetRect = targetElement.getBoundingClientRect();
                    const offset = -400; // Décalage de 100px
                    const targetPosition = targetRect.top + window.scrollY - offset;

                    // Faire défiler vers la position calculée
                    window.scrollTo({ top: targetPosition, behavior: 'smooth' });
                }
            }
        };

        // Ajouter un écouteur d'événement pour gérer les changements d'ancre
        window.addEventListener('hashchange', handleHashChange);

        // Appel initial pour gérer l'ancre actuelle (si présente)
        handleHashChange();

        // Nettoyage de l'écouteur d'événement lorsque le composant est démonté
        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, []);

    return (
        <main>
            <div className='mt-12'>
                <ImageCarousel />
                <Titre texte="Projets de toiture et couverture" />
                <div className='hidden sm:block'>
                    <Intro2 />
                </div>

                <ProjetsCompos
                    title="Réfection d'une maison historique au centre ville de Munster"
                    ID='p1'
                    description="Réfection d'une maison historique au centre ville de Munster . 
                    Isolation entre chevrons en laine de bois , plus une isolation Sarking par dessus également en laine de bois.
                    Réfection de toutes les zingueries et lucarnes 
                    Et pose de tuiles plates dites Alsaciennes ou 'Bieberschwantz'."
                    images={['images/1.png', 'images/2.png', 'images/3.png', 'images/4.png']}
                    reviewspointListTitle="Détails techniques"
                    points={[
                        'Réfection de toutes les zingueries et lucarnes ',
                        'pose de tuiles plates dites Alsaciennes ou "Bieberschwantz" ',
                    ]}
                    reviews={[
                        { name: 'Prénom Nom', review: ' En attente de votre retour ou commentaire sur votre expérience ! ' },
                    ]}
                />

                <ProjetsCompos
                    title="Toiture à Logelbach"
                    ID='p2'
                    description="
                    Couverture en tuiles Arboise rectangulaire couleur ardoisée de chez Edilians, très beau résultat final qui rappelle l'ancienne couverture en ardoise, Habillage des planches de rives en tole prélaquée blanche pour l'accord avec la façade qui a été isolée."
                    images={['images/log1.png', 'images/log2.png', 'images/log3.png', 'images/log4.png']}
                    reviewspointListTitle="Détails techniques"
                    points={[
                        'Tuiles : Arboise rectangulaire couleur ardoisée ',
                        'Planches de rives : tole prélaquée blanche',
                        'Isolation : Façade isolée',
                    ]}
                    reviews={[
                        { name: 'Marine', review: 'Non seulement ils ont utilisé des matériaux de haute qualité, mais leur attention aux détails et leur expertise dans la sélection des tuiles appropriées ont donné à ma maison une toute nouvelle allure. ' },
                    ]}
                />

                <ProjetsCompos
                    title="Maison  au centre ville de Munster"
                    ID='p3'
                    description="Maison au centre ville de Munster , le magasin de chaussures Élégance à revêtu sa nouvelle toiture en Kergoat de chez Eternit . Avec réfection de la charpente des lucarnes, habillés sur mesures en tôles pré laquée blanche Ral 9010."
                    images={['images/munst4.png', 'images/munst2.png', 'images/munst3.png', 'images/munst1.png']}
                    reviewspointListTitle="Détails techniques"
                    points={[
                        'Tuiles : Kergoat de chez Eternit',
                        'Zinguerie : Tôles pré laquée blanche Ral 9010',
                        'Charpente : réfection des lucarnes',
                    ]}
                    reviews={[
                        { name: 'Prénom Nom', review: 'En attente de votre retour ou commentaire sur votre expérience !' },
                    ]}
                />

                <ProjetsCompos
                    title="Marcairie perdue dans la montagne"
                    ID='p4'
                    description="Encore une marcairie perdue dans la montagne rénovée. Les deux bâtiments remis à neuf. Couverture en tuiles H10 nuagés de chez Edilians, avec isolation en sarking épaisseur 130mm, R = 6. Le top pour une maison en montagne."
                    images={['images/mar3.png', 'images/mar2.png', 'images/mar1.png', 'images/mar4.png']}
                    reviewspointListTitle="Détails techniques"
                    points={[
                        'Tuiles : H10 nuagés de chez Edilians',
                        'Isolation : sarking épaisseur 130mm, R = 6',
                    ]}
                    reviews={[
                        { name: 'Prénom Nom', review: ' En attente de votre retour ou commentaire sur votre expérience ! ' },
                    ]}
                />
            </div>
        </main>
    )
}

export default Projets;
