import React, { useEffect, useRef } from 'react';
import ImageComparisonSlider from './ImageComparisonSlider';

interface AvantApresProps {
  title: string;
  content: string;
  imageSrc: string;
}

export const AvantApres = ({ title, content, imageSrc }: AvantApresProps) => {
  const textDivRef = useRef<HTMLDivElement | null>(null);
  const greenDivRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (textDivRef.current && greenDivRef.current) {
      const textDivHeight = textDivRef.current.clientHeight;
      greenDivRef.current.style.height = `${textDivHeight + 40}px`;
    }
  }, [content]);

  return (
    <div className="p-5 lg:p-10 mt-10">
      <div className="w-full lg:flex">
        <div className="w-full lg:w-1/2">
        <div className="relative bg-customGrey rounded-xl aspect-w-16 aspect-h-9">
            <div
              className="opacity-70 w-full h-full object-cover bg-customGree2 rounded-xl"
            />
            <div
              className=" top-0 left-0 bg-white border-2 transform -translate-x-5 -translate-y-5 flex items-center justify-center p-5 rounded-xl"
              ref={textDivRef}
            >
              <div className="max-w-full"> 
                <h4 className="text-customGreen text-lg font-semibold mb-2 ">
                  {title}
                </h4>
                <p className="text-black text-sm">{content}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full lg:w-1/2">
          <div className="p-5 hidden lg:block">
            <ImageComparisonSlider />
          </div>
        </div>
      </div>
    </div>
  );
};
