

interface OverlappingDivsProps {
  text: string;
}

export const OverlappingDivs = ({ text }: OverlappingDivsProps) => {
  const halfTextLength = Math.ceil(text.length / 2.37);
  const mobileText = text.substr(0, halfTextLength);

  return (
    <div className="relative h-[22vh]">
      <div className="absolute top-0 left-0 w-full h-full bg-customGrey opacity-80 transform -translate-x-5 translate-y-5 hidden md:block rounded-xl"></div>
      <div className="absolute top-0 left-0 h-40 w-30 md:hidden">
        <img
          src="images/logoicon.png"
          alt="logo"
          className="w-full h-full md:hidden"
        />
      </div>
      <div className={`absolute top-0 left-0 w-full h-full bg-white border-2 transform ld:translate-x-5 -translate-y-5 rounded-xl flex items-center justify-center p-10 mt-5  ${'md:bg-opacity-100 bg-opacity-100'}`}>
        {window.innerWidth < 768 ? mobileText : text}
      </div>
      
    </div>
  );
}




