import React from "react";
import ImageCarousel from "../components/ImageCarousel";
import { Titre } from "../components/Titre";
import QualityLogos from "../components/QualityLogos";
import Contacteznous from "../components/Contacteznous";
import Retrouveznous from "../components/Retrouveznous";
import EmbeddedMap from "../components/EmbeddedMap";
import { SousTitre } from "../components/SousTitre";

export const Contact = () => {
  const logos = [
    "images/qualite1.png",
    "images/logo40.png",
    "images/CMA.jpg",
    // Ajoutez les URLs des logos de qualité
  ];

  return (
    <main>
      <div className="mt-12">
        <ImageCarousel />
      </div>

      <Titre texte="Contactez-nous pour vos besoins toiture et couverture" />

      <div className="p-12 flex flex-col md:flex-row w-full">
        <div className="w-full md:w-1/2 md:pr-2">
          <Contacteznous />
        </div>
        <div className="md:w-3 bg-customGreen2 rounded-full my-4 md:my-20"></div>
        <div className="w-full md:w-1/2 md:pl-2 mt-4 md:mt-0">
          <Retrouveznous />
        </div>
      </div>

      <SousTitre texte="Contactez-nous pour vos besoins en entreprise de toiture et couverture" />
      <EmbeddedMap
        apiKey="AIzaSyB543agVBBVe77o50drfonN65waQ9TEq3E"
        latitude={48.8566}
        longitude={2.3522}
      />

      <SousTitre texte="Une entreprise qualifiée en toiture et couverture" />
      <QualityLogos logos={logos} />
    </main>
  );
};
