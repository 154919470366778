import React from 'react';
import { Titre } from './Titre';

const CoverComponent = () => {
  return (
    <div className="flex h-auto" id="isolation">

      {/* Div de gauche */}
      <div className="hidden lg:block w-1/2">
        <div className="flex flex-col lg:m-10">
          {/* Grande photo */}
          <img src="images/116764239_1800412506831315_7754877139507890182_n.jpg" alt="Isolation de toiture" className="object-cover rounded-xl mb-5 w-full h-20 transform transition-transform hover:scale-105" />

          {/* Deux photos en bas */}
          <div className="flex">
            <div className="w-2/3 lg:mr-2.5">
              <img src="images/isolation.jpg" alt="Isolation premier projet" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
            <div className="w-1/3 lg:ml-2.5">
              <img src="images/projets1.jpg" alt="Isolation deuxième projet" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
          </div>
        </div>
      </div>

      {/* Div de droite */}
      <div className="lg:w-1/2 p-4 border-2 rounded-xl bg-customGrey">
        <div className='text-customGreen text-3xl font-inter font-medium mt-5 mb-5 md:mt-10 md:mb-5'>
          Isolation
        </div>
        <div className="font-bold mb-3">
          <div>L'Isolation Sarking</div>
        </div>
        <div className='mb-6'>
          <div>Cette technique d'isolation avancée consiste à isoler votre toiture par l'extérieur, garantissant une performance énergétique optimale et une protection efficace contre les éléments. Le système Sarking implique l'installation d'une isolation continue sous les chevrons, créant ainsi une barrière thermique continue et éliminant les ponts thermiques. </div>
        </div>
        <div className="font-bold mb-3">
          <div>Isolation de Toiture Traditionnelle</div>
        </div>
        <div className='mb-6'>
          <div> Que vous optiez pour une isolation par soufflage, une isolation en panneaux rigides ou d'autres méthodes traditionnelles, notre priorité est de créer un environnement intérieur confortable et écoénergétique. </div>
        </div>
        <div className="border-t-2 border-green-500 my-4"></div>
      </div>
    </div>
  );
}

export default CoverComponent;
