import React from 'react';

export const MentionsLegales = () => {
  return (
    <div className="max-w-4xl mx-auto mt-12 p-4 pt-20 ">
      <h2 className="text-2xl font-bold mb-4 text-customGreen ">Mentions Légales</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 bg-gray-300 p-5 rounded-xl mb-5 text-black">

        {/* Contenu à gauche */}
        <div className="mb-6">
          <div className="text-xl text-customGreen font-semibold mb-2">Propriétaire du Site :</div>
          <p>Nom de l'entreprise : ANDRE BAUMGART</p>
          <p>Forme juridique : Société à Responsabilité Limitée (SARL)</p>
          <p>Date de création de l'entreprise : 01-07-1990</p>
          <p>Adresse postale : 41 GRAND RUE, 68380 METZERAL, France</p>
          <p>Téléphone : 03 89 21 69 39 </p>
          <p>Hébergement : Hostinger</p>

          <div className="mt-6">
            <div className="text-xl font-semibold mb-2 text-customGreen">Identifiants :</div>
            <p>Numéro SIREN : 378882005</p>
            <p>Numéro SIRET (siège) : 37888200500028</p>
            <p>Numéro TVA Intracommunautaire : FR27378882005</p>
            <p>Numéro RCS : Colmar B 378 882 005</p>
          </div>
        </div>

        {/* Contenu à droite */}
        <div>
          <div className="mb-6">
            <div className="text-xl font-semibold mb-2 text-customGreen">Informations Juridiques :</div>
            <p>Statut RCS : INSCRITE au greffe de Colmar</p>
            <p>Date d'immatriculation RCS : 03-08-1990</p>
            <p>Date d'enregistrement INSEE : 01-07-1990</p>
            <p>Juridiction : Greffe du Tribunal d'Instance de Colmar</p>
            <p>N° dossier : 1990B00348</p>
            <p>Code greffe : 6851</p>
          </div>

          <div className="mb-6">
            <div className="text-xl font-semibold mb-2 text-customGreen">Taille de l'Entreprise :</div>
            <p>Effectif : 3 à 5 salariés</p>
            <p>Capital social : 7 622,45 €</p>
          </div>

          <div>
            <div className="text-xl font-semibold mb-2 text-customGreen">Créateur du site</div>
            <p>Créateur du site : GASSMANN Victor </p>
              <p>Contact : <a href="mailto:victorgassmannpro@gmail.com"> victorgassmannpro@gmail.com</a></p>

          </div>
        </div>
        
      </div>
    </div>
  );
};
