import React from 'react';
import { Titre } from './Titre';

const CoverComponentG = () => {
  return (
    <div className="flex h-auto" id="bardage">

      {/* Div de gauche (contenant les textes) */}
      <div className="lg:w-1/2 p-4 border-2 rounded-xl bg-customGrey">
        <div className='text-customGreen text-3xl font-inter font-medium mt-5 mb-5 md:mt-10 md:mb-5'>
          Bardage
        </div>
        <div className="font-bold mb-3">
          <div>Transformez l'Esthétique et la Performance de Votre Bâtiment</div>
        </div>
        <div className='mb-6'>
          <div>Notre équipe d'experts en bardage est déterminée à transformer l'apparence et la performance de votre bâtiment en utilisant une variété de matériaux de haute qualité. Que vous souhaitiez moderniser l'extérieur de votre maison, améliorer l'isolation ou protéger votre bâtiment des intempéries, nous avons les compétences et l'expérience nécessaires pour réaliser votre vision. </div>
        </div>
        <div className="font-bold mb-3">
          <div>Une Solution de Protection Extérieure à Long Terme</div>
        </div>
        <div className='mb-6'>
          <div>Nos services de bardage vont au-delà de l'aspect esthétique en offrant une enveloppe extérieure robuste et résistante aux éléments. Grâce à notre expertise dans le choix des matériaux et des techniques d'installation, nous créons des systèmes de bardage qui résistent aux intempéries, aux rayons UV et aux changements climatiques. </div>
        </div>
        <div className="border-t-2 border-green-500 my-4"></div>
      </div>

      {/* Div de droite (contenant les images) */}
      <div className="hidden lg:block w-1/2">
        <div className="flex flex-col lg:m-10">
          {/* Grande photo */}
          <img src="images/projets3.jpg" alt="Bardage projet 1" className="object-cover rounded-xl mb-5 w-full h-20 transform transition-transform hover:scale-105" />

          {/* Deux photos en bas */}
          <div className="flex">
            <div className="w-1/3 lg:mr-2.5">
              <img src="images/bardage1.jpg" alt="Bardage projet 2" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
            <div className="w-2/3 lg:ml-2.5">
              <img src="images/bardage2.jpg" alt="Bardage projet 3" className="object-cover rounded-xl w-full transform transition-transform hover:scale-105" />
            </div>
          </div>
        </div>
        
      </div>

    </div>
    
  );
}

export default CoverComponentG;
