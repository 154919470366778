import React from 'react';

const NotFound = () => {
  return (
    <div className='flex flex-col items-center justify-center mt-20 p-10'>
      <div className="w-80 h-90 overflow-hidden animate-wiggle mb-8">
        <img src='images/logoiconpng.png' alt="Error 404" className="object-cover w-full h-full" />
      </div>
      <h1 className="text-4xl font-bold mb-4 text-center">Oups! Page introuvable</h1>
      <p className="text-lg text-center mb-8">La page demandée est introuvable.</p>
      <a href="/" className="text-customGreen hover:underline">Retour à la page d'accueil</a>
    </div>
  );
};

export default NotFound;
