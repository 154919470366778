import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ArticleComponent = () => {
  const [isArticleExpanded, setIsArticleExpanded] = useState(false);
  const [isImageExpanded, setIsImageExpanded] = useState(false);

  const handleExpandArticle = () => {
    setIsArticleExpanded(!isArticleExpanded);
  };

  const handleExpandImage = () => {
    setIsImageExpanded(true);
  };

  const handleCloseImage = () => {
    setIsImageExpanded(false);
  };
      

  return (
    <div className="relative p-8">
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="w-full lg:col-span-1 lg:col-start-1">
          <img
            src="images/article.jpg"
            alt="Image de l'article"
            onClick={handleExpandImage}
            className="cursor-pointer w-full h-auto"
          />
        </div>

        <div className="lg:col-span-2 bg-gray-200 p-4 rounded-md">
          <h1 className="text-2xl font-bold text-customGreen">Paroles de pros / Interview Adhérent<br /><br /></h1>
          <h2 className="text-lg text-black font-bold">L'entreprise Baumgart s'offre une nouvelle jeunesse à Metzeral avec l'arrivée de Christophe et Sylvie aux manettes de l'entreprise familiale</h2>

          <div
            className={`mt-4 ${isArticleExpanded ? 'max-h-full' : 'max-h-[400px]'}`}
            style={{
              overflow: 'hidden',
            }}
          >
            <p>
              L'entreprise de couverture Baumgart a été créée par André, le papa, en 1983 à Metzeral. Son fils, Christophe le rejoint en 2001 et sa fille, Sylvie arrive en qualité de secrétaire administrative en 2019. Cette deuxième génération apporte un souffle nouveau à l'entreprise, tout en conservant cette proximité avec sa clientèle, véritable marque de fabrique de la maison.<br ></br><br ></br>
            </p>
            <p>
              Ici, les courriers ne sont pas envoyés mais déposés directement sur chantier. Le champ d'action de cette entreprise familiale est d'un rayon de 8 km depuis son siège, à Metzeral. « Au plus loin, nous intervenons à Munster, précise Sylvie Baumgart. Nous savons nous adapter aux contraintes liées aux chalets de montagne comme les difficultés d'accès, notamment. Dans certains, il n'y a même pas d'électricité. Mais nous, la montagne, on l'aime. Nos clients sont sympas, serviables, et ils apprécient notre présence. 90% des chantiers réalisés par Baumgart sont des rénovations.<br ></br><br ></br>
            </p>
            <h2 className="text-lg text-black font-bold">Des Investissements pour le bien des salariés</h2>
            <p>
              L'entreprise familiale est spécialisée en zinguerie, pose d'isolation sur le toit et bardage. « Ici, nous avons la fierté de tout faire nous-mêmes », sourit Sylvie Baumgart. Sur les chantiers depuis son plus jeune âge aux côtés de son père, la jeune femme a choisi de rejoindre les rangs familiaux après quelques années à exercer dans le domaine du social. « Petite, j'avais même ma propre poche à clous », sourit-elle. Elle est aujourd'hui le couteau suisse de l'entreprise. Une fois par semaine, elle prend le plateau pour chercher en personne le matériel. « Les frais de livraison ont explosé, c'est un moyen de les limiter », souligne Sylvie Baumgart.<br ></br><br ></br>
            </p>
            <h2 className="text-lg text-black font-bold">Coup de frais sur l'image de l'entreprise</h2>
            <p>
              Sylvie et Christophe ont également travaillé sur l'image de l'entreprise par le flocage des véhicules et des T-shirts. « Nous avons également obtenu la qualification Artisans d'Alsace par la Chambre des Métiers d'Alsace. Nous continuons à proposer des stages de découverte en vue de recruter de nouveaux apprentis. Nous nous sommes inscrits dans la continuité avec des touches de changement. » Une entreprise adhérente à la Fédération du BTP 68 depuis sa création. « Elle nous permet de rencontrer des personnes confrontées aux mêmes difficultés que nous. Je me suis rapprochée de la Fédé pour des besoins de formation, par exemple, explique Sylvie. Elle m'a également dirigée vers des aides pour pouvoir accueillir des apprentis. Nous pouvons la solliciter à la moindre question ou problématique, elle est à l'écoute et réactive. » Et si Baumgart a su se faire un nom et une réputation sans les réseaux sociaux, les enfants eux, prennent le virage et communiquent via leur Page Facebook sur laquelle ils mettent en valeur leurs chantiers par des photos et des vidéos réalisées par drone. Le moyen de s'offrir une belle vitrine.
            </p>
          </div>

          {isArticleExpanded ? (
            <button
              onClick={handleExpandArticle}
              className="mt-4 bg-customGreen text-white p-2 rounded hover:bg-customGreen2"
            >
              Réduire l'article
            </button>
          ) : (
            <button
              onClick={handleExpandArticle}
              className="mt-4 bg-customGreen text-white p-2 rounded hover:bg-customGreen2"
            >
              Continuer de lire l'article
            </button>
          )}
        </div>
        {isImageExpanded && (
     <div className="fixed inset-0 flex items-center justify-center z-50">
     <div className="absolute inset-0 bg-black opacity-70"></div>
     <div className="bg-white z-10 p-4 rounded-lg overflow-auto">
      

       <img
         src="images/article.jpg"
         alt="Image de l'article en grand"
         className="w-auto max-w-full max-h-[100vh]"
       />
       <button
         onClick={handleCloseImage}
         className="absolute top-2 right-2 text-white text-2xl cursor-pointer"
       >
         &#x2715;
       </button>
     </div>
   </div>
      )}
      </div>
    </div>
  );
};

export default ArticleComponent;
