import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import '../App.css';

interface CardProps {
  title: string;
  text: string;
  logoUrl: string;
  sectionId: string; 
  backgroundColor: string;
  titleColor: string;   
}

const Card = ({
  title,
  text,
  logoUrl,
  sectionId,
  backgroundColor,
  titleColor,
}: CardProps) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      className={`card relative rounded-md shadow-md p-8 h-[20rem] transition-transform ${
        isHovered ? 'scale-105' : ''
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ backgroundColor }}
    >
      <p className={`card-title ${titleColor}`}>{title}</p>
      <p className="small-desc">{text}</p>
      <div className="go-corner">
        <div className="go-arrow">→</div>
      </div>
      {isHovered && (
        <div className="absolute bottom-4 right-4">
        <a
  href={`Prestations?section=${sectionId}&offset=-60`} 
  className="flex items-center space-x-2 text-white hover:text-gray-300"
>
  <span>En savoir plus</span>
  <FontAwesomeIcon icon={faArrowRight} />
</a>



        </div>
      )}
    </div>
  );
};

export default Card;
