import React, { useState } from 'react';

interface QualityLogosProps {
  logos: string[]; // Tableau d'URLs d'images des logos
}

const QualityLogos = ({ logos }: QualityLogosProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };

  return (
    <div className="flex mb-10 justify-center items-center w-full overflow-x-scroll md:overflow-hidden py-4 md:py-0">
      <div className="flex space-x-8 md:space-x-4 lg:space-x-8">
        {logos.map((logoUrl, index) => (
          <div
            key={index}
            className={`transition-transform duration-300 transform ${
              hoveredIndex === index ? 'scale-105' : ''
            }`}
            onMouseEnter={() => handleMouseEnter(index)}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={logoUrl}
              alt={`Logo ${index + 1}`}
              className="h-[6rem] md:h-[5rem] lg:h-[6rem]"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualityLogos;
