import { OverlappingDivs } from '../components/OverlappingDivs';

export const Intro2 = () => {
  const text = `Découvrez en images nos projets de couverture, zinguerie, isolation, bardage et bien plus encore. Chaque chantier est une démonstration de notre expérience et de notre engagement envers la qualité. Notre équipe dévouée travaille avec passion pour transformer les idées en réalité, en veillant à ce que chaque détail soit méticuleusement pris en compte. De la conception initiale à la mise en œuvre finale, nous nous efforçons de dépasser les attentes de nos clients à chaque étape. `;

  return (
<div className="mt-16 pt-0 pb-0 pl-20 pr-20 flex flex-col md:flex-row flex items-center justify-center rounded-xl">
  <div className="mb-20 w-3/4 md:w-3/3 px-4 rounded-xl">
    <OverlappingDivs text={text} />
  </div>
 
</div>





  );
}
