import React from 'react';
import { OverlappingDivs } from '../components/OverlappingDivs';

export const Intro = () => {
  const text = `André BAUMGART et Fils SARL, une entreprise familiale spécialisée en couverture depuis plus de 40 ans, est votre partenaire de confiance pour protéger votre habitat avec excellence.
Nous sommes fiers d'offrir des prestations de haute qualité pour les toits de maisons, neuves ou en rénovation.`;

  return (
    <div className="px-6 md:pl-20 md:pr-20 flex flex-col md:flex-row items-center justify-center">
      <div className="w-full md:w-2/3 md:px-4 mb-6 md:mb-0">
        <OverlappingDivs text={text} />
      </div>
      <div className="w-full md:w-1/3 hidden md:block relative">
      <img
      src="images/logoiconpng.png"
      alt="logo"
      className="w-full"
    />
      </div>
    </div>
  );
}
