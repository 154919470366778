interface TitreProps {
  texte: string;
}

export const Titre = ({ texte }: TitreProps) => {
  return (
    <div className="bg-customGreen py-6">
      <h1 className='text-center text-white text-3xl font-inter font-medium'>{texte}</h1>
    </div>
  );
}
