import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { photos } from './photos.js';

const GalerieComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const openLightbox = (index : any) => {
    setCurrentImageIndex(index);
    setModalIsOpen(true);
  };
  

  const closeLightbox = () => {
    setCurrentImageIndex(0);
    setModalIsOpen(false);
  };

  const goToPrevious = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
    }
  };

  const goToNext = () => {
    if (currentImageIndex < photos.length - 1) {
      setCurrentImageIndex(currentImageIndex + 1);
    }
  };

  return (
    <div>
      <Gallery photos={photos} onClick={(event, obj) => openLightbox(obj.index)} />
      <Modal 
      open={modalIsOpen} 
      onClose={closeLightbox} 
      center 
      classNames={{ modal: 'customModal' }}
      closeIcon={<span className="text-white text-4xl mr-3">&times;</span>}

      >
        <div className={`relative ${modalIsOpen ? 'opacity-100 transition-opacity' : 'opacity-0'}`}>
        <img src={photos[currentImageIndex].src} alt={`Image ${currentImageIndex + 1}`} />



          <button
            onClick={goToPrevious}
            className="absolute top-1/2 left-2 text-white text-2xl z-10 bg-customGreen p-2 rounded-full transform -translate-y-1/2"
          >
            &larr;
          </button>
          <button
            onClick={goToNext}
            className="absolute top-1/2 right-2 text-white text-2xl z-10 bg-customGreen p-2 rounded-full transform -translate-y-1/2"
          >
            &rarr;
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GalerieComponent;
