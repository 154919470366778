interface TitrePrincipalProps {
    texte: string;
  }
  
  export const TitrePrincipal = ({ texte }: TitrePrincipalProps) => {
    return (
        <div className="text-5xl md:text-7xl font-bold text-center text-white mt-20 mb-16 px-4 md:px-0">
        {texte}</div>
    );
  }
  