
interface SousTitreProps {
    texte: string;
  }
  
  export const SousTitre = ({ texte }: SousTitreProps) => {
    return (
      <h2 className='text-center text-customGreen text-2xl font-inter font-medium mt-5 mb-5 md:mt-10 md:mb-5'>{texte}</h2>
    );
  }
  